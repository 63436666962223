<!--
 * @Author: Neko
 * @Date: 2021-01-13 11:49:03
 * @LastEditTime: 2021-01-15 16:02:09
 * @LastEditors: Neko
-->
<template>
  <div class="pagination__container">
    <el-pagination
      class="text-center"
      hide-on-single-page
      :current-page="data.pageNo"
      :page-size="pageSize"
      :total="data.total"
      background
      layout="prev, pager, next"
      @current-change="onPageChange"
    />
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
  name: 'Pagination',

  emits: ['current-change'],

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  setup(props, { emit }) {
    const pageSize = computed(() => +process.env.VUE_APP_PAGE_SIZE)

    const onPageChange = page => {
      emit('current-change', page)
    }

    return {
      onPageChange,

      pageSize
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination__container {
  text-align: center;
}
</style>
